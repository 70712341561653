import axios from "axios";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import { loginSuccess } from "state";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

// Toaster import
import { successToaster, errorToaster } from "shared/toaster";

// Encryption
import { encrypt } from "shared/encryption";

// API URL
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

// Footer
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" href="https://technexinnovations.in/">
                TNI
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export default function Login() {
    const dispatch = useDispatch();

    // Variables State
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        const loginCreds = {
            email: email,
            password: password,
            rememberMe: rememberMe,
        };

        axios
            .post(adminApiUrl + "login", loginCreds, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                const result = response.data;
                if (result.success) {
                    // set cookies, if remember me is checked, expires in 30 days, else expires in 1 day
                    Cookies.set("token", result.token, { expires: rememberMe ? 30 : 1 });
                    Cookies.set("isAuthenticated", await encrypt(true), { expires: rememberMe ? 30 : 1 });
                    Cookies.set("userData", result.userData, { expires: rememberMe ? 30 : 1 });

                    // dispatch action
                    dispatch(loginSuccess());

                    // show success message
                    successToaster("Login Successfully");

                    // redirect to dashboard, sligtly delay to show success message
                    setTimeout(() => {
                        window.location.href = "/dashboard";
                    }, 2000);
                } else {
                    if (result.message !== "ok") return errorToaster(result.message);
                    console.log(result.message);
                }
            })
            .catch((error) => {
                console.error("Login error Error:", error);
                errorToaster("Something went wrong");
            });
    };

    return (
        <Container component="main" sx={{ width: "700px" }}>
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 16,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.4)",
                    backdropFilter: "blur(8px)",
                    borderRadius: 10,
                    px: 4,
                    py: 6,
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus value={email} onChange={(e) => setEmail(e.target.value)} />
                    <TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <FormControlLabel control={<Checkbox onChange={() => setRememberMe(!rememberMe)} />} label="Remember me" />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2" sx={{ color: "text.secondary" }}>
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2" sx={{ color: "text.secondary" }}>
                                {"Need Help?"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 8 }} />
        </Container>
    );
}
