import { createSlice } from "@reduxjs/toolkit";

// Global Slice
const globalSlice = createSlice({
    name: "global",
    initialState: {
        mode: "dark",
        isAuthenticated: false,
        userData: null,
    },
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === "light" ? "dark" : "light";
        },
        loginSuccess: (state, action) => {
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.isAuthenticated = false;
        },
    },
});

// Export Reducers
export const { setMode, loginSuccess, logout } = globalSlice.actions;
export default globalSlice.reducer;
