import React from "react";
import About from "scenes/about";
import Login from "scenes/login";
import Layout from "scenes/layout";
import Slider from "scenes/slider";
import Gallery from "scenes/gallery";
import Dashboard from "scenes/dashboard";
import Newsletter from "scenes/newsletter";
import Schemes from "scenes/schemes";
import Updates from "scenes/updates";
import { Navigate, Route, Routes } from "react-router-dom";
import PostNewsletter from "scenes/post-newsletter";
import AddSchemes from "./scenes/schemes/add-schemes";
import News from "./scenes/news";
import AddNews from "./scenes/news/add-news";

const AppRouter = ({ isAuthenticated }) => (
    <Routes>
        <Route path="/" element={<Layout />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/about" element={isAuthenticated ? <About /> : <Navigate to="/login" />} />
            <Route path="/gallery" element={isAuthenticated ? <Gallery /> : <Navigate to="/login" />} />
            <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
            <Route path="/gallery/manage-banner" element={isAuthenticated ? <Slider /> : <Navigate to="/login" />} />
            <Route path="/newsletter" element={isAuthenticated ? <Newsletter /> : <Navigate to="/login" />} />
            <Route path="/newsletter/post" element={isAuthenticated ? <PostNewsletter /> : <Navigate to="/login" />} />
            <Route path="/updates" element={isAuthenticated ? <Updates /> : <Navigate to="/login" />} />
            <Route path="/schemes" element={isAuthenticated ? <Schemes /> : <Navigate to="/login" />} />
            <Route path="/schemes/add" element={isAuthenticated ? <AddSchemes /> : <Navigate to="/login" />} />
            <Route path="/news" element= {<News />} /> 
            <Route path="/news/add" element={<AddNews />} />
        </Route>
        <Route path="/login" element={<Login />} />
    </Routes>
);

export default AppRouter;
