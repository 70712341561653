import axios from "axios";
import Cookies from "js-cookie";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StartIcon from "@mui/icons-material/Start";
import React, { useState, useEffect } from "react";
import { Button, IconButton } from "@mui/material";

// Header component
import Header from "components/Header";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import DeleteIcon from "@mui/icons-material/Delete";
// Toaster import
import { successToaster, errorToaster } from "shared/toaster";

// Sweet alerts
import { confirmAlert } from "shared/alerts";

// Loader Component
import Loader from "shared/Loader";

// ADMIN API URL
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

export default function News() {
    // Navigate to a new page
    const navigate = useNavigate();

    // Hovered item state
    const [hoveredItem, setHoveredItem] = React.useState(null);
    const [states, setStates] = useState({
        news: [],
        loading: true,
    });

    // Destructure state variables for easier access
    const { news, loading } = states;

    // Fetch news from the API
    const fetchNews = () => {
        try {
            axios
                .get(adminApiUrl + "news", {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("token"),
                    },
                })
                .then((response) => {
                    const result = response.data;
                    if (result.success) {
                        setStates((prevStates) => ({
                            ...prevStates,
                            news: result?.news,
                        }));
                    } else {
                        console.log(result.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setStates((prevStates) => ({
                        ...prevStates,
                        loading: false,
                    }));
                });
        } catch (error) {
            console.log(error);
            setStates((prevStates) => ({
                ...prevStates,
                loading: false,
            }));
            errorToaster("Something went wrong");
        }
    };

    // Delete the Images from the gallery
    const handleImgDelete = (index) => {
        // Start the loader
        setStates((prevStates) => ({
            ...prevStates,
            loading: true,
        }));

        // Confirm the alert
        confirmAlert("Delete News", "Are you sure you want to delete this news?", async () => {
            try {
                axios
                    .delete(adminApiUrl + "news/" + news[index]._id, {
                        headers: {
                            Authorization: "Bearer " + Cookies.get("token"),
                        },
                    })
                    .then((response) => {
                        const result = response.data;
                        if (result.success) {
                            successToaster("news deleted successfully");
                            fetchNews();
                        } else {
                            errorToaster(result.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        errorToaster("Something went wrong");
                    });
            } catch (error) {
                console.log(error);
                errorToaster("Something went wrong");
            } finally {
                // Stop the loader
                setStates((prevStates) => ({
                    ...prevStates,
                    loading: false,
                }));
            }
        });
    };

    // Truncate the content, only show first 8 words
    const truncateContent = (content) => {
        const words = content.split(" ");
        if (words.length > 8) {
            return words.slice(0, 8).join(" ") + " ...";
        }
        return content;
    };

    // Fetch news on component mount
    useEffect(() => {
      fetchNews();
    }, []);

    return (
        <Box m="1.5rem 2.5rem" pb={3}>
            {loading && <Loader />}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Header title="Published News" subtitle="Latest news & updates available on the website" />
                <div>
                    <Button
                        variant="contained"
                        endIcon={<StartIcon />}
                        sx={{ marginTop: "2rem" }}
                        onClick={() => {
                            navigate(`/news/add`);
                        }}
                    >
                        Publish News
                    </Button>
                </div>
            </div>

            <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: 1500, height: 700, overflowY: "scroll" }}>
                    <ImageList variant="masonry" cols={3} gap={8} >
                        {news?.map((item, index) => (
                            <ImageListItem key={item._id} onMouseEnter={() => setHoveredItem(index)} onMouseLeave={() => setHoveredItem(null)} style={{borderRadius:"5px", backgroundColor:"white", color:"black"}}>
                                <img
                                    srcSet={`https://api.sunilmendhe.in/${item.image}`}
                                    src={`https://api.sunilmendhe.in/${item.image}`}
                                    alt={item._id}
                                    loading="lazy"
                                    style={{borderRadius:"5px 5px 0 0"}}
                                />
                                {hoveredItem === index && (
                                    <div className="overlay">
                                        <IconButton onClick={() => handleImgDelete(index)} color="inherit">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}

                                <p style={{margin:"3px 0 3px 3px"}}>{truncateContent(item.title)}</p>
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </Box>
        </Box>
    );
}
