import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Toaster function for success
const successToaster = (message) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: localStorage.getItem("theme") || "dark",
    });
};

// Toaster function for error
const errorToaster = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: localStorage.getItem("theme") || "dark",
    });
};

// Toaster function for warning
const warningToaster = (message) => {
    toast.warning(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: localStorage.getItem("theme") || "dark",
    });
};

// Toaster function for info
const infoToaster = (message) => {
    toast.info(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: localStorage.getItem("theme") || "dark",
    });
};

export { successToaster, errorToaster, warningToaster, infoToaster };
