import React from "react";
import Cookies from "js-cookie";
import List from "@mui/material/List";
import Header from "components/Header";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { Box, AppBar, Toolbar, Button, Typography, IconButton } from "@mui/material";

// Loader Component
import Loader from "shared/Loader";

// Sweet alerts
import { confirmAlert } from "shared/alerts";

// Toaster import
import { successToaster, errorToaster } from "shared/toaster";

// ADMIN API URL
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

export default function Schemes() {
    // Navigate to a new page
    const navigate = useNavigate();

    // Set state variables
    const [schemes, setSchemes] = React.useState([]);

    // Set loading state
    const [loading, setLoading] = React.useState(true);

    // Fetch all schemes
    const fetchSchemes = async () => {
        try {
            const response = await fetch(`${adminApiUrl}schemes`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Cookies.get("token")}`,
                },
            });

            const data = await response.json();

            if (response.status === 200) {
                setSchemes(data?.schemes);
                setLoading(false);
            } else {
                errorToaster(data.message);
            }
        } catch (error) {
            errorToaster(error.message);
        }
    };

    // Delete scheme
    const deleteScheme = async (id) => {
        // Confirm alert
        confirmAlert("Delete Scheme","Are you sure you want to delete this scheme?", async () => {
            try {
                // Set loading state
                setLoading(true);

                // Make api call
                const response = await fetch(`${adminApiUrl}scheme/${id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Cookies.get("token")}`,
                    },
                });

                // get response data
                const data = await response.json();

                // check if response is ok
                if (response.status === 200) {
                    successToaster("Scheme deleted successfully");
                    fetchSchemes();
                } else {
                    errorToaster(data.message);
                }
            } catch (error) {
                errorToaster(error.message);
            }
        }, async () => {
            // Set loading state
            setLoading(false);
        });
    };

    // On component mount
    React.useEffect(() => {
        fetchSchemes();
    }, []);

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" m="1.5rem 2.5rem">
            <Header title="Schemes" subtitle="List of all added central and state government schemes" />
            <AppBar position="static" style={{ margin: "36px 0" }}>
                <Toolbar>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            <Typography variant="h5">Total Schemes Added: {schemes && schemes?.length}</Typography>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    navigate(`/schemes/add`);
                                }}
                            >
                                Add New Schemes
                            </Button>
                        </div>
                    </Box>
                </Toolbar>
            </AppBar>

            {loading ? (
                <Loader />
            ) : (
                <List sx={{ width: "100%", bgcolor: "background.paper", overflowY: "scroll", height: "600px" }}>
                    {schemes.map((scheme, index) => (
                        <div key={index}>
                            <ListItem alignItems="flex-start">
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src={`https://api.sunilmendhe.in/${scheme.iconUrl}`} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={scheme.title}
                                    secondary={
                                        <React.Fragment>
                                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                                                {scheme.type}
                                            </Typography>
                                            {" — " + scheme?.detail}
                                        </React.Fragment>
                                    }
                                />
                                <IconButton edge="end" aria-label="edit">
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={(e) => deleteScheme(scheme?._id)} sx={{ mx: 3 }}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                        </div>
                    ))}
                </List>
            )}
        </Box>
    );
}
