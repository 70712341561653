import axios from "axios";
import Cookies from "js-cookie";
import JoditEditor from "jodit-react";
import Card from "@mui/material/Card";
import { useState, useRef } from "react";
import { Box, AppBar } from "@mui/material";
import { CameraAlt } from "@mui/icons-material";
import CardMedia from "@mui/material/CardMedia";
import { Button, IconButton } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import CardContent from "@mui/material/CardContent";
import PublishIcon from "@mui/icons-material/Publish";
import InputAdornment from "@mui/material/InputAdornment";

// Loader
import Loader from "shared/Loader";

// Header component
import Header from "components/Header";

// Sweet alerts
import { confirmAlert } from "shared/alerts";

// Constant variables
import { constants } from "shared/constants";

// Import toaster
import { successToaster, errorToaster } from "shared/toaster";

// Import validation
import { validateTextLength, validateFile } from "shared/validation";

// Import env variables
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

export default function AddNews() {
    // Initialize state using useState hook
    const [state, setState] = useState({
        title: "",
        content: "",
        selectedImage: null,
        uploadLoading: false,
        showUploadIcon: false,
    });

    // Destructure state values for easier access
    const { title, content, selectedImage, showUploadIcon, uploadLoading } = state;

    // Desctructure contant values for easier access
    const { MAX_BANNER_SIZE, MIN_BANNER_SIZE, ALLOWED_IMAGE_TYPES } = constants;

    const editor = useRef(null);
    const fileInputRef = useRef(null);
    const config = { height: 600, theme: "dark" };

    // Function to handle mouse enter event
    const handleMouseEnter = () => setState({ ...state, showUploadIcon: true });

    // Function to handle mouse leave event
    const handleMouseLeave = () => setState({ ...state, showUploadIcon: false });

    // Function to handle icon click event
    const handleIconClick = () => document.getElementById("hiddenFileInput").click();

    // Function to handle title change
    const handleTitleChange = (title) => setState({ ...state, title });

    // Function to handle content change
    const handleContentChange = (content) => setState({ ...state, content });

    // Function to handle on image change
    const handleImageChange = (event) => {
        const selectedImage = event.target.files[0];

        // Validate file
        const fileError = validateFile(selectedImage, "News banner", {
            types: ALLOWED_IMAGE_TYPES,
            min: MIN_BANNER_SIZE,
            max: MAX_BANNER_SIZE,
        });
        if (fileError) {
            setState({ ...state, selectedImage: null });
            fileInputRef.current.value = "";
            return errorToaster(fileError);
        }

        // Update selectedFile in formData state
        setState({ ...state, selectedImage });
    };

    // Function to handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate image
        const isErr = validateFile(selectedImage, "News banner", {
            types: ALLOWED_IMAGE_TYPES,
            min: MIN_BANNER_SIZE,
            max: MAX_BANNER_SIZE,
        });
        if (isErr) return errorToaster(isErr);

        // Validate title
        const titleErr = validateTextLength(title, "Title", 3, 100);
        if (titleErr) return errorToaster(titleErr);

        // Validate content
        const contentErr = validateTextLength(content, "Content", 3, 10000);
        if (contentErr) return errorToaster(contentErr);

        // Confirm news publish
        confirmAlert("Publish News", "Are you sure you want to publish this news?", handlePublish, async () => {
            setState({ ...state, uploadLoading: false });
        });

        // Function to handle news publish
        async function handlePublish() {
            // Set loading to true
            setState({ ...state, uploadLoading: true });

            // Set headers
            const headers = {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${Cookies.get("token")}`,
            };

            // Set form data
            const formData = new FormData();
            formData.append("title", title);
            formData.append("content", content);
            formData.append("image", selectedImage);

            // Send request to the server
            try {
                const response = await axios.post(`${adminApiUrl}news`, formData, { headers });

                // Destructure response data
                const { success } = response.data;

                // Check if request was successful
                if (success) {
                    // Reset input fields
                    setState({ ...state, title: "", content: "", selectedImage: null, uploadLoading: false });

                    // Show success message
                    successToaster("News published successfully");

                    // Redirect to news page
                    window.location.href = "/news";
                }
            } catch (error) {
                // Set loading to false
                setState({ ...state, uploadLoading: false });

                // Show error message
                errorToaster("An error occurred. Please try again");
            }
        }
    };

    return (
        <Box m="1.5rem 2.5rem" pb={3}>
            {uploadLoading && <Loader />}
            <form autoComplete="off" encType="multipart/form-data">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Header title="Publish News" subtitle="Share news & updates on the website" />
                    <div>
                        <Button variant="contained" onClick={handleSubmit} endIcon={<PublishIcon />} sx={{ marginTop: "2rem" }}>
                            Publish
                        </Button>
                    </div>
                </div>

                <Card variant="outlined" sx={{ my: 5, borderRadius: 2 }}>
                    <Box
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        sx={{
                            position: "relative",
                            backgroundColor: showUploadIcon ? "rgba(0, 0, 0, 0.6)" : "transparent",
                            transition: "background-color 0.2s ease-in-out",
                            zIndex: 500,
                        }}
                    >
                        <CardMedia
                            component="img"
                            sx={{
                                p: 3,
                                height: "500px",
                                objectFit: "fill",
                            }}
                            image={
                                selectedImage
                                    ? URL.createObjectURL(selectedImage)
                                    : "https://scontent.fnag9-1.fna.fbcdn.net/v/t39.30808-6/426001287_952003256488254_5388563522169603937_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=5f2048&_nc_ohc=XAuTUXwNrGsAX86LO1N&_nc_ht=scontent.fnag9-1.fna&oh=00_AfAVGLuPUW5H1QXRvltA5hk8uoEgooNS6QQRO6-0Czjz3g&oe=65F39F00"
                            }
                            alt="Live from space album cover"
                        />
                        <IconButton
                            aria-label="Upload Image"
                            onClick={() => {
                                handleIconClick();
                            }}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                display: showUploadIcon ? "block" : "none",
                                color: "white",
                                zIndex: 99,
                                backgroundColor: "rgba(0, 0, 0, 0.6)",
                                transition: "background-color 0.2s ease-in-out",
                                alignItems: "center",
                            }}
                        >
                            <CameraAlt fontSize="large" />
                            <input
                                id="hiddenFileInput"
                                type="file"
                                accept="image/*"
                                ref={fileInputRef}
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                                multiple={false}
                            />
                        </IconButton>
                    </Box>

                    <CardContent>
                        <Box p={1}>
                            <AppBar position="static" style={{ height: "90px", padding: "15px", borderRadius: "5px" }}>
                                <Box>
                                    <div>
                                        <FormControl variant="filled" fullWidth>
                                            <FilledInput
                                                style={{ fontSize: "18px" }}
                                                value={title}
                                                onChange={(e) => handleTitleChange(e.target.value)}
                                                startAdornment={<InputAdornment position="start">Headline:</InputAdornment>}
                                            />
                                        </FormControl>
                                    </div>
                                </Box>
                            </AppBar>
                            <Box mt={5}>
                                <JoditEditor
                                    ref={editor}
                                    tabIndex={1}
                                    value={content}
                                    config={config}
                                    onBlur={(newContent) => handleContentChange(newContent)}
                                    onChange={(newContent) => {}}
                                />
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </form>
        </Box>
    );
}
