import CryptoJS, { AES } from "crypto-js";

// Encryption Key and IV
const key = process.env.REACT_APP_SIGNED_HEX_KEY;
const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_SIGNED_HEX_IV.slice(0, process.env.REACT_APP_SIGNED_HEX_IV.length));

// Encrypt Functions
export const encrypt = async (data) => {
    return AES.encrypt(JSON.stringify(data), key, { iv }).toString();
};

// Decrypt Functions
export const decrypt = (data) => {
    return JSON.parse(AES.decrypt(data, key, { iv }).toString(CryptoJS.enc.Utf8));
};
