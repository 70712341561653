import Cookies from "js-cookie";
import JoditEditor from "jodit-react";
import { Stack, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useState, useRef } from "react";
import { CameraAlt } from "@mui/icons-material";
import PublishIcon from "@mui/icons-material/Publish";
import { Box, Paper, AppBar, Toolbar, Button, TextField, IconButton, Select, MenuItem, InputLabel, FormControl } from "@mui/material";

// Loader
import Loader from "shared/Loader";

// Header component
import Header from "components/Header";

// Avatar
import avatar from "../../../assets/avatar.png";

// Import validation file
import { validateTextLength, validateFile, validateUrl, validateArrayLength } from "shared/validation";

// Toaster
import { successToaster, errorToaster } from "shared/toaster";

// Sweet alerts
import { confirmAlert } from "shared/alerts";

// Constant variables
import { constants } from "shared/constants";

// ADMIN API URL
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

// SCHEME ENVIRONMENT VARIABLES
const schemeTypes = process.env.REACT_APP_SCHEME_TYPE ? process.env.REACT_APP_SCHEME_TYPE.split(",") : [];

export default function AddSchemes() {
    // Navigate to a new page
    const navigate = useNavigate();

    // Editor initialization
    const editor = useRef(null);

    // State initialization
    const [formData, setFormData] = useState({
        tags: [],
        link: "",
        type: "",
        title: "",
        detail: "",
        content: "",
        category: "",
        selectedIcon: null,
        uploadLoading: false,
        showUploadIcon: false,
    });

    // Destructure state
    const { link, type, content, category, title, detail, selectedIcon, showUploadIcon, uploadLoading, tags } = formData;

    // Desctructure contant values for easier access
    const { MIN_ICON_SIZE, MAX_ICON_SIZE, ALLOWED_IMAGE_TYPES } = constants;

    // Editor configuration
    const config = {
        height: 500,
        theme: "dark",
        placeholder: "Write complete details of the scheme...",
    };

    // Handle mouse enter
    const handleMouseEnter = () => {
        setFormData((prevData) => ({
            ...prevData,
            showUploadIcon: true,
        }));
    };

    // Handle mouse leave
    const handleMouseLeave = () => {
        setFormData((prevData) => ({
            ...prevData,
            showUploadIcon: false,
        }));
    };

    // Handle Scheme Icon upload
    const handleIconChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const iconErr = validateFile(file, "Icon " + file.name, {
                types: ALLOWED_IMAGE_TYPES,
                min: MIN_ICON_SIZE,
                max: MAX_ICON_SIZE,
            });
            if (iconErr) {
                setFormData((prevData) => ({
                    ...prevData,
                    selectedIcon: null,
                }));

                // Reset the file input
                event.target.value = "";

                errorToaster(iconErr);
                return;
            }

            setFormData((prevData) => ({
                ...prevData,
                selectedIcon: file,
            }));
        }
    };

    // Handle Scheme Icon upload
    const handleIconClick = () => {
        const hiddenFileInput = document.getElementById("hiddenFileInput");
        hiddenFileInput.click();
    };

    // Handle Scheme Tags
    const handleKeyDown = (event) => {
        if (event.keyCode === 32) {
            const newTag = event.target.value.trim().toLowerCase();
            if (newTag) {
                if (formData.tags.map((tag) => tag.toLowerCase()).includes(newTag)) {
                    errorToaster("Tag already exists");
                    return;
                }
                const tagsErr = validateArrayLength([...tags, newTag], "Tags", { min: 1, max: 10 });
                if (tagsErr) {
                    errorToaster(tagsErr);
                    return;
                }
                setFormData((prevData) => ({
                    ...prevData,
                    tags: [...prevData.tags, newTag],
                }));
                event.target.value = "";
            }
        }
    };

    // Handle Tags Delete
    const handleDelete = (tagToRemove) => {
        setFormData((prevData) => ({
            ...prevData,
            tags: prevData.tags.filter((tag) => tag !== tagToRemove),
        }));
    };

    // Handle Scheme form field change
    const handleFieldChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    // Handle Scheme Publish
    const handlePublish = async (e) => {
        e.preventDefault();

        // Show confirmation alert
        confirmAlert(
            "Add Scheme",
            "Are you sure you want to add this scheme?",
            async () => {
                // Set upload loading
                setFormData((prevData) => ({ ...prevData, uploadLoading: true }));

                // Set error array
                const isAnyErr = [];

                // Get file name
                const fileName = selectedIcon ? selectedIcon?.name : "";

                // Validate icon
                const iconErr = validateFile(selectedIcon, "Icon " + fileName, {
                    types: ALLOWED_IMAGE_TYPES,
                    min: MIN_ICON_SIZE,
                    max: MAX_ICON_SIZE,
                });
                if (iconErr) isAnyErr.push(iconErr);

                // Validate title
                const titleErr = validateTextLength(title, "Scheme Title", { min: 5, max: 100 });
                if (titleErr) isAnyErr.push(titleErr);

                // Validate detail
                const detailErr = validateTextLength(detail, "Scheme Detail", { min: 5, max: 500 });
                if (detailErr) isAnyErr.push(detailErr);

                // Validate link
                const linkErr = validateUrl(link, "Scheme Link", { min: 5, max: 100 });
                if (linkErr) isAnyErr.push(linkErr);

                // Validate type
                const typeErr = validateTextLength(type, "Scheme Type", { min: 1, max: 100 });
                if (typeErr) isAnyErr.push(typeErr);

                // Validate category
                const categoryErr = validateTextLength(category, "Scheme Category", { min: 1, max: 100 });
                if (categoryErr) isAnyErr.push(categoryErr);

                // Validate content
                const contentErr = validateTextLength(content, "Scheme Content", { min: 5, max: 100000 });
                if (contentErr) isAnyErr.push(contentErr);

                // Validate tags
                const tagsErr = validateArrayLength(tags, "Tags", { min: 1, max: 10 });
                if (tagsErr) isAnyErr.push(tagsErr);

                // Return if any error
                if (isAnyErr.length > 0) {
                    isAnyErr.forEach((err) => errorToaster(err));
                    setFormData((prevData) => ({ ...prevData, uploadLoading: false }));
                    return;
                }

                // Create form data
                const formData = new FormData();

                // Append form data
                formData.append("title", title);
                formData.append("detail", detail);
                formData.append("link", link);
                formData.append("type", type);
                formData.append("category", category);
                formData.append("content", content);
                formData.append("icon", selectedIcon);

                // Append tags
                tags.forEach((tag) => {
                    formData.append("tags", tag);
                });

                // API call
                const response = await fetch(adminApiUrl + "scheme", {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${Cookies.get("token")}`,
                    },
                    body: formData,
                });

                // Get response data
                const data = await response.json();

                // Check if response is success
                if (data.success) {
                    setFormData((prevData) => ({ ...prevData, uploadLoading: false }));
                    successToaster("Scheme added successfully.");

                    // Return to schemes page
                    navigate("/schemes");
                } else {
                    setFormData((prevData) => ({ ...prevData, uploadLoading: false }));
                    if (data?.duplicates && data?.duplicates[0]?.message) {
                        errorToaster(data?.duplicates[0]?.message);
                        return;
                    } else if (data?.message) {
                        errorToaster(data?.message);
                        return;
                    } else {
                        errorToaster("Something went wrong");
                    }
                }
            },
            async () => {
                // Reset the state variables
                setFormData((prevStates) => ({
                    ...prevStates,
                    uploadLoading: false,
                }));
            }
        );
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" m="1.5rem 2.5rem">
            <Header title="Add Schemes" subtitle="Add all central and state Government Schemes" />
            {uploadLoading && <Loader />}
            <form encType="multipart/form-data" noValidate="">
                <AppBar position="static" style={{ margin: "36px 0" }}>
                    <Toolbar>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <Button
                                    variant="contained"
                                    sx={{ mr: 2 }}
                                    onClick={() => {
                                        navigate(`/schemes`);
                                    }}
                                >
                                    Schemes
                                </Button>
                            </div>
                            <div>
                                <Button variant="contained" endIcon={<PublishIcon />} onClick={(e) => handlePublish(e)}>
                                    Publish
                                </Button>
                            </div>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Paper sx={{ my: 3, p: 3 }}>
                    <Paper sx={{ my: 3, p: 3 }}>
                        <Box
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            sx={{
                                position: "relative",
                                backgroundColor: showUploadIcon ? "rgba(0, 0, 0, 0.6)" : "transparent",
                                transition: "background-color 0.2s ease-in-out",
                                zIndex: 500,
                            }}
                            p={3}
                        >
                            {!selectedIcon ? (
                                <img
                                    src={avatar}
                                    alt="Live from space album cover"
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "5px",
                                        margin: "15px",
                                    }}
                                />
                            ) : (
                                <img
                                    src={URL.createObjectURL(selectedIcon)}
                                    alt="Live from space album cover"
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        border: "1px solid",
                                        borderRadius: "50%",
                                        padding: "5px",
                                        margin: "15px",
                                    }}
                                />
                            )}

                            <IconButton
                                aria-label="Upload Image"
                                onClick={handleIconClick}
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    display: showUploadIcon ? "block" : "none",
                                    color: "white",
                                }}
                            >
                                <CameraAlt fontSize="large" />
                                <input id="hiddenFileInput" type="file" accept="image/*" style={{ display: "none" }} onChange={handleIconChange} />
                            </IconButton>
                        </Box>
                        <Box p={3}>
                            <TextField fullWidth label="Scheme Name" value={title} onChange={(e) => handleFieldChange("title", e.target.value)} />
                            <TextField fullWidth label="Scheme Description" value={detail} onChange={(e) => handleFieldChange("detail", e.target.value)} sx={{ mt: 2 }} />
                            <TextField fullWidth label="Scheme Original link" value={link} onChange={(e) => handleFieldChange("link", e.target.value)} sx={{ mt: 2 }} />
                            <Box sx={{ py: 2 }}>
                                <TextField id="tags" label="Tags" placeholder="Enter tags" onKeyDown={handleKeyDown} fullWidth />
                                {tags.length > 0 && (
                                    <Box display="flex" justifyContent="space-around" sx={{ pt: 2 }}>
                                        {tags.map((tag) => (
                                            <Stack direction="row" spacing={1} key={tag}>
                                                <Chip label={tag} onDelete={() => handleDelete(tag)} />
                                            </Stack>
                                        ))}
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ minWidth: 120, mb: 5, display: "flex" }}>
                                <FormControl fullWidth sx={{ pr: 5 }}>
                                    <InputLabel id="demo-simple-select-label">Category</InputLabel>
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={category} label="Category" onChange={(e) => handleFieldChange("category", e.target.value)}>
                                        <MenuItem value="State Government">State Government</MenuItem>
                                        <MenuItem value="Central Government">Central Government</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={type} label="Type" onChange={(e) => handleFieldChange("type", e.target.value)}>
                                        {schemeTypes.map((schemeType, index) => {
                                            return (
                                                <MenuItem key={index} value={schemeType}>
                                                    {schemeType}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <JoditEditor
                                ref={editor}
                                value={content}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) => handleFieldChange("content", newContent)} // preferred to use only this option to update the content for performance reasons
                                config={config}
                            />
                        </Box>
                    </Paper>
                </Paper>
            </form>
        </Box>
    );
}
