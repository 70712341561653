import Header from "components/Header";
import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme } from "@mui/material";
import { useGetSubscribersQuery } from "state/api";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";

// For date formatting
import { format } from "date-fns";

const Subscribers = () => {
    // Theme initialization
    const theme = useTheme();

    // values to be sent to the backend
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState({});
    const [search, setSearch] = useState("");

    // values to be used in the frontend
    const [searchInput, setSearchInput] = useState("");
    const { data, isLoading } = useGetSubscribersQuery({
        page,
        limit,
        sort: JSON.stringify(sort),
        search,
    });

    // Format the createdAt date
    const formattedData = data?.subscribers.map((subscriber) => ({
        ...subscriber,
        createdAt: format(new Date(subscriber.createdAt), "yyyy-MM-dd | hh:mm a "),
    }));

    const columns = [
        {
            field: "email",
            headerName: "Subscriber Email",
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "Subscription Date",
            flex: 1,
        },
    ];

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="" subtitle="Entire list of Newsletter Subscribers" />
            <Box
                mt={2}
                height="70vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    loading={isLoading || !data}
                    getRowId={(row) => row?._id}
                    rows={(data && formattedData) || []}
                    columns={columns}
                    rowCount={(data && data?.count) || 0}
                    rowsPerPageOptions={[20, 50, 100]}
                    pagination
                    page={page}
                    limit={limit}
                    paginationMode="server"
                    sortingMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onLimitChange={(newLimit) => setLimit(newLimit)}
                    onSortModelChange={(newSortModel) => setSort(...newSortModel)}
                    slots={{ Toolbar: DataGridCustomToolbar }}
                    slotProps={{
                        toolbar: { searchInput, setSearchInput, setSearch },
                    }}
                />
            </Box>
        </Box>
    );
};

export default Subscribers;
