//---------- import Components
import axios from "axios";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material";
import Switch from "@mui/material/Switch";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import ImageList from "@mui/material/ImageList";
import Typography from "@mui/material/Typography";
import { Stack, Chip, Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ImageListItem from "@mui/material/ImageListItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import DeleteIcon from "@mui/icons-material/Delete";

// Import validation file
import { validateTextLength, validateFile, validateArrayLength } from "shared/validation";

// Toaster import
import { successToaster, errorToaster } from "shared/toaster";

// Constant variables
import { constants } from "shared/constants";

// Sweet alerts
import { confirmAlert } from "shared/alerts";

// Loader Component
import Loader from "shared/Loader";

// ADMIN API URL
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

export default function Gallery() {
    // Navigation
    const navigate = useNavigate();

    // Theme
    const theme = useTheme();

    // Style
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 1200,
        bgcolor: theme.palette.background.default,
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    // Android 12 Switch
    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        "& .MuiSwitch-track": {
            borderRadius: 22 / 2,
            "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                width: 16,
                height: 16,
            },
            "&:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main)
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            "&:after": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main)
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 12,
            },
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 16,
            height: 16,
            margin: 2,
        },
    }));

    // Variables declaration
    const [hoveredItem, setHoveredItem] = useState(null);
    const [hoveredImage, setHoveredImage] = useState(null);
    const [openCreateNewPostModal, setOpenCreateNewPostModal] = React.useState(false);

    // Functions
    const handleImageLeave = () => setHoveredImage(null);
    const handleImageHover = (image) => setHoveredImage(image);
    const handleOpenCreateNewPostModal = () => setOpenCreateNewPostModal(true);
    const handleCloseCreateNewPostModal = () => setOpenCreateNewPostModal(false);

    // State variables
    const [states, setStates] = useState({
        images: [],
        content: "",
        loading: true,
        galleries: [],
        isTwitter: false,
        isGallery: false,
        twitterTags: [],
        selectedImages: [],
        uploadLoading: false,
    });

    // Destructure state variables for easier access
    const { images, content, loading, galleries, isTwitter, isGallery, twitterTags, selectedImages, uploadLoading } = states;

    // Desctructure contant values for easier access
    const { MIN_POST_GALLERY_SIZE, MAX_POST_GALLERY_SIZE, ALLOWED_IMAGE_TYPES } = constants;

    // Fetch galleries from the API
    const fetchGalleries = () => {
        try {
            axios
                .get(adminApiUrl + "galleries", {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("token"),
                    },
                })
                .then((response) => {
                    const result = response.data;
                    if (result.success) {
                        setStates((prevStates) => ({
                            ...prevStates,
                            galleries: result?.galleries,
                        }));
                    } else {
                        console.log(result.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setStates((prevStates) => ({
                        ...prevStates,
                        loading: false,
                    }));
                });
        } catch (error) {
            console.log(error);
            setStates((prevStates) => ({
                ...prevStates,
                loading: false,
            }));
            errorToaster("Something went wrong");
        }
    };

    // Handle image selection
    const handleImageSelection = (event) => {
        const files = event.target.files;
        if (files.length > 4) return errorToaster("You can upload maximum 4 images at a time");
        if (files) {
            // Validate the each file
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileErr = validateFile(file, file.name, {
                    types: ALLOWED_IMAGE_TYPES,
                    min: MIN_POST_GALLERY_SIZE,
                    max: MAX_POST_GALLERY_SIZE,
                });
                if (fileErr) return errorToaster(fileErr);
            }

            const newSelectedImages = Array.from(files).map((file) => URL.createObjectURL(file));
            setStates((prevStates) => ({
                ...prevStates,
                selectedImages: [...prevStates.selectedImages, ...newSelectedImages],
                images: [...prevStates.images, ...files],
            }));
        }
    };

    // Add the tag
    const handleKeyDown = (event) => {
        if (event.keyCode === 32) {
            const newTag = event.target.value.trim().toLowerCase();
            if (newTag) {
                if (states.twitterTags.map((tag) => tag.toLowerCase()).includes(newTag)) {
                    errorToaster("Tag already exists.");
                    return;
                }
                const tagsErr = validateArrayLength([...twitterTags, newTag], "Tags", { min: 1, max: 10 });
                if (tagsErr) {
                    errorToaster(tagsErr);
                    return;
                }
                setStates((prevStates) => ({
                    ...prevStates,
                    twitterTags: [...prevStates.twitterTags, newTag],
                }));
                event.target.value = "";
            }
        }
    };

    // Delete the tag
    const handleDelete = (tagToRemove) => {
        setStates((prevStates) => ({
            ...prevStates,
            twitterTags: prevStates.twitterTags.filter((tag) => tag !== tagToRemove),
        }));
    };

    // Deselect the image
    const handleDeselect = (image) => {
        setStates((prevStates) => ({
            ...prevStates,
            selectedImages: prevStates.selectedImages.filter((img) => img !== image),
            images: prevStates.images.filter((img) => img.name !== image.name),
        }));
    };

    // Delete the Images from the gallery
    const handleImgDelete = (index) => {
        // Confirm the alert
        confirmAlert("Delete Gallery Image", "Are you sure you want to delete this image from the gallery?", async () => {
            try {
                axios
                    .delete(adminApiUrl + "gallery/" + galleries[index]._id, {
                        headers: {
                            Authorization: "Bearer " + Cookies.get("token"),
                        },
                    })
                    .then((response) => {
                        const result = response.data;
                        if (result.success) {
                            successToaster("Image deleted successfully");
                            fetchGalleries();
                        } else {
                            errorToaster(result.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        errorToaster("Something went wrong");
                    });
            } catch (error) {
                console.log(error);
                errorToaster("Something went wrong");
            }
        });
    };

    // Handle twitter switch change
    const handleTwitterChange = (event) => {
        setStates((prevStates) => ({
            ...prevStates,
            isTwitter: event.target.checked,
        }));
    };

    // Handle gallery switch change
    const handleGalleryChange = (event) => {
        console.log(event.target.checked);
        setStates((prevStates) => ({
            ...prevStates,
            isGallery: event.target.checked,
        }));
    };

    // Handle content change
    const handleContentChange = (event) => {
        setStates((prevStates) => ({
            ...prevStates,
            content: event.target.value,
        }));
    };

    // Handle post submission logic here
    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Close the modal
        handleCloseCreateNewPostModal();

        // Show confirm alert
        confirmAlert(
            "Create Post",
            "Are you sure you want to create this post?",
            async () => {
                // Set the uploadLoading state variable to true
                setStates((prevStates) => ({
                    ...prevStates,
                    uploadLoading: true,
                }));

                // Set error array
                const isAnyErr = [];

                // Validate the images
                if(images.length > 4) return errorToaster("You can upload maximum 4 images at a time");

                // Validate the each image
                if (images.length > 0) {
                    for (let i = 0; i < images.length; i++) {
                        const image = images[i];
                        const imageErr = validateFile(image, image.name, {
                            types: ALLOWED_IMAGE_TYPES,
                            min: MIN_POST_GALLERY_SIZE,
                            max: MAX_POST_GALLERY_SIZE,
                        });
                        if (imageErr) return errorToaster(imageErr);
                    }
                } else {
                    isAnyErr.push("Please select atleast one image.");
                }

                // Check if the content is empty when isTwitter is true
                if (isTwitter) {
                    // Validate the content
                    const contentErr = validateTextLength(content, "Post content", { min: 1, max: 1000 });
                    if (contentErr) isAnyErr.push(contentErr);

                    // Validate the twitter tags
                    const twitterTagsErr = validateArrayLength(twitterTags, "Twitter tags", {
                        min: 1,
                        max: 10,
                    });
                    if (twitterTagsErr) isAnyErr.push(twitterTagsErr);
                }

                // If there is any error then display it
                if (isAnyErr.length > 0) {
                    isAnyErr.forEach((err) => errorToaster(err));
                    setStates((prevStates) => ({
                        ...prevStates,
                        uploadLoading: false,
                    }));
                    return;
                }

                // Create a new formData
                const formData = new FormData();

                // Add tags to the formData
                for (let i = 0; i < twitterTags.length; i++) {
                    formData.append("twitterTags", twitterTags[i]);
                }

                // Add images to the formData
                for (let i = 0; i < images.length; i++) {
                    formData.append("images", images[i]);
                }

                // Add isTwitter to the formData
                formData.append("isTwitter", isTwitter);

                // Add isGallery to the formData
                formData.append("isGallery", isGallery);

                // Add content to the formData
                formData.append("content", content);

                // Upload the post to the API
                axios
                    .post(adminApiUrl + "post", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: "Bearer " + Cookies.get("token"),
                        },
                    })
                    .then((response) => {
                        const result = response.data;
                        if (result.success) {
                            successToaster("Post uploaded successfully");

                            // Reset the state variables
                            setStates((prevStates) => ({
                                ...prevStates,
                                images: [],
                                content: "",
                                twitterTags: [],
                                selectedImages: [],
                                isTwitter: false,
                                isGallery: false,
                            }));

                            handleCloseCreateNewPostModal();
                            fetchGalleries();
                        } else {
                            errorToaster(result.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        errorToaster("Something went wrong");
                    })
                    .finally(() => {
                        setStates((prevStates) => ({
                            ...prevStates,
                            uploadLoading: false,
                        }));
                    });
            },
            async () => {
                // Reset the state variables
                setStates((prevStates) => ({
                    ...prevStates,
                    uploadLoading: false,
                }));
            }
        );
    };

    // Fetch galleries from the API
    useEffect(() => {
        fetchGalleries();
    }, []);

    return (
        <Box sx={{ flexGrow: 1, py: 5 }}>
            {loading && <Loader />}
            <Box display="flex" justifyContent="center">
                <AppBar position="static" style={{ width: "1500px" }}>
                    <Toolbar>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <Button
                                    variant="contained"
                                    startIcon={<ViewCarouselIcon />}
                                    onClick={() => {
                                        navigate(`/gallery/manage-banner`);
                                    }}
                                >
                                    Banner Images
                                </Button>
                            </div>
                            <div>
                                <Button variant="contained" startIcon={<PostAddIcon />} onClick={handleOpenCreateNewPostModal}>
                                    Create New Post
                                </Button>

                                {uploadLoading ? (
                                    <Loader />
                                ) : (
                                    <Modal open={openCreateNewPostModal} onClose={handleCloseCreateNewPostModal}>
                                        <form encType="multipart/form-data" style={{ overflowY: "scroll" }}>
                                            <Box sx={style} borderRadius={6}>
                                                <Box display="flex" justifyContent="space-between">
                                                    <FormControlLabel control={<Android12Switch />} checked={isTwitter} onChange={handleTwitterChange} label="Twitter" />
                                                    <FormControlLabel control={<Android12Switch />} checked={isGallery} onChange={handleGalleryChange} label="Web Gallery" />
                                                </Box>
                                                <Box display="flex" flexDirection="column" sx={{ py: 5 }}>
                                                    <TextField id="outlined-textarea" label="Write a post" placeholder="..." multiline fullWidth rows={5} onChange={handleContentChange} disabled={!isTwitter} />
                                                    <Box sx={{ py: 2 }}>
                                                        <TextField id="twitterTags" label="Twitter Tags" placeholder="Enter tags for twitter & press space button" onKeyDown={handleKeyDown} fullWidth disabled={!isTwitter} />
                                                        <Box display="flex" justifyContent="space-around" sx={{ py: 3 }}>
                                                            {twitterTags?.map((tag) => (
                                                                <Stack direction="row" spacing={1} key={tag}>
                                                                    <Chip label={tag} onDelete={() => handleDelete(tag)} />
                                                                </Stack>
                                                            ))}
                                                        </Box>
                                                        <Box>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    textAlign: "center",
                                                                    border: 1,
                                                                    borderRadius: 4,
                                                                    p: 2,
                                                                }}
                                                            >
                                                                <Typography>Upload Images</Typography>
                                                                <IconButton onClick={() => document.getElementById("imageInput").click()}>
                                                                    <PhotoCameraIcon />
                                                                </IconButton>
                                                                <input type="file" accept="image/*" id="imageInput" multiple onChange={handleImageSelection} style={{ display: "none" }} disabled={!isTwitter && !isGallery} />
                                                            </Box>

                                                            {/* Display selected images */}
                                                            <Box display="flex" justifyContent="center">
                                                                <Box>
                                                                    {selectedImages.length > 0 && (
                                                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                                                            {selectedImages?.map((image) => (
                                                                                <Grid item xs={12} md={6} lg={3} key={image}>
                                                                                    <div
                                                                                        style={{
                                                                                            position: "relative",
                                                                                            display: "inline-block",
                                                                                        }}
                                                                                        onMouseEnter={() => handleImageHover(image)}
                                                                                        onMouseLeave={handleImageLeave}
                                                                                    >
                                                                                        <img
                                                                                            src={image}
                                                                                            alt="Selected"
                                                                                            style={{
                                                                                                width: "100px",
                                                                                                height: "100px",
                                                                                                padding: "10px",
                                                                                            }}
                                                                                        />
                                                                                        {hoveredImage === image && (
                                                                                            <div
                                                                                                style={{
                                                                                                    position: "absolute",
                                                                                                    top: 0,
                                                                                                    left: 0,
                                                                                                    right: 0,
                                                                                                    bottom: 0,
                                                                                                    background: "rgba(0, 0, 0, 0.5)",
                                                                                                    display: "flex",
                                                                                                    justifyContent: "center",
                                                                                                    alignItems: "center",
                                                                                                    cursor: "pointer",
                                                                                                }}
                                                                                            >
                                                                                                <IconButton onClick={() => handleDeselect(image)} style={{ color: "white" }}>
                                                                                                    <CloseIcon />
                                                                                                </IconButton>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </Grid>
                                                                            ))}
                                                                        </Grid>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Button variant="outlined" color="secondary" startIcon={<CloseIcon />} onClick={handleCloseCreateNewPostModal}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="contained" color="primary" endIcon={<SendIcon />} disabled={!isTwitter && !isGallery} onClick={handleFormSubmit}>
                                                        Post
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </form>
                                    </Modal>
                                )}
                            </div>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: 1500, height: 700, overflowY: "scroll" }}>
                    <ImageList variant="masonry" cols={3} gap={8}>
                        {galleries?.map((item, index) => (
                            <ImageListItem key={item._id} onMouseEnter={() => setHoveredItem(index)} onMouseLeave={() => setHoveredItem(null)}>
                                <img srcSet={`https://api.sunilmendhe.in/${item.image}`} src={`https://api.sunilmendhe.in/${item.image}`} alt={item._id} loading="lazy" />
                                {hoveredItem === index && (
                                    <div className="overlay">
                                        <IconButton onClick={() => handleImgDelete(index)} color="inherit">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </Box>
        </Box>
    );
}
