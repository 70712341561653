// Max sizes for file uploads
export const constants = {
    MAX_ICON_SIZE: parseInt(process.env.REACT_APP_MAX_ICON_SIZE, 10) || 2097152, // 25MB
    MAX_BANNER_SIZE: parseInt(process.env.REACT_APP_MAX_BANNER_SIZE, 10) || 26214400, // 25MB
    MAX_ATTACHMENT_SIZE: parseInt(process.env.REACT_APP_MAX_ATTACHMENT_SIZE, 10) || 26214400, // 25MB
    MAX_POST_GALLERY_SIZE: parseInt(process.env.REACT_APP_MAX_POST_GALLERY_SIZE, 10) || 5242880, // 5MB
    MAX_PROFILE_PICTURE_SIZE: parseInt(process.env.REACT_APP_MAX_PROFILE_PICTURE_SIZE, 10) || 5242880, // 5MB

    // Min sizes for file uploads
    MIN_ICON_SIZE: parseInt(process.env.REACT_APP_MIN_ICON_SIZE, 10) || 1, // 1B
    MIN_BANNER_SIZE: parseInt(process.env.REACT_APP_MIN_BANNER_SIZE, 10) || 1, // 1B
    MIN_ATTACHMENT_SIZE: parseInt(process.env.REACT_APP_MIN_ATTACHMENT_SIZE, 10) || 1, // 1B
    MIN_POST_GALLERY_SIZE: parseInt(process.env.REACT_APP_MIN_POST_GALLERY_SIZE, 10) || 1, // 1B
    MIN_PROFILE_PICTURE_SIZE: parseInt(process.env.REACT_APP_MIN_PROFILE_PICTURE_SIZE, 10) || 1, // 1B

    // Allowed file formats for images
    ALLOWED_IMAGE_TYPES: process.env.REACT_APP_ALLOWED_IMAGE_TYPES.split(",").map((type) => type.trim()),

    // Allowed file formats for documents
    ALLOWED_DOCUMENT_TYPES: process.env.REACT_APP_ALLOWED_DOCUMENT_TYPES.split(",").map((type) => type.trim()),
};
