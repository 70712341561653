import axios from "axios";
import Cookies from "js-cookie";
import JoditEditor from "jodit-react";
import Card from "@mui/material/Card";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { Box, AppBar } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { useState, useRef, useEffect } from "react";
import PublishIcon from "@mui/icons-material/Publish";

// Loader
import Loader from "shared/Loader";

// Header component
import Header from "components/Header";

// Import toaster
import { successToaster, errorToaster } from "shared/toaster";

// Import validation
import { validateTextLength } from "shared/validation";

// Sweet alerts
import { confirmAlert } from "shared/alerts";

// Import env variables
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

export default function Updates() {
    const editor = useRef(null);
    const config = { height: 500, theme: "dark" };

    // States
    const [states, setStates] = useState({
        title: "",
        details: "",
        scheduleDate: "",
        scheduleUpdateId: "",
        uploadLoading: false,
    });

    // Destructure states
    const { title, details, scheduleUpdateId, uploadLoading } = states;

    // Update state
    const updateState = (key, value) => {
        setStates((prevState) => ({ ...prevState, [key]: value }));
    };

    // Format schedule date
    const formatScheduleDate = (date) => {
        const formatedDate = new Date(date).toLocaleString("default", {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
        return formatedDate;
    };

    // Validate schedule date
    const scheduleDateValidation = (date) => {
        const today = new Date();
        const scheduleDate = new Date(date);

        if (!date) return "Schedule date should not be empty";
        if (isNaN(scheduleDate)) return "Schedule date is invalid";
        if (scheduleDate < today) return "Schedule date should not be older date";

        return null;
    };

    // Get update data
    const getUpdateData = () => {
        axios
            .get(`${adminApiUrl}update`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get("token")}`,
                },
            })
            .then((response) => {
                if (response.data.success) {
                    const { title, details, scheduleDate, _id } = response.data.update;

                    // Update states
                    updateState("details", details);
                    updateState("scheduleUpdateId", _id);
                    updateState("scheduleDate", scheduleDate);
                    updateState("title", title + " " + formatScheduleDate(scheduleDate));
                } else {
                    errorToaster("Something went wrong");
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                errorToaster("Something went wrong");
            });
    };

    // Update schedule date
    const updateScheduleDate = async (e) => {
        confirmAlert(
            "Update Schedule Date",
            "Are you sure you want to update schedule date?",
            async () => {
                const date = e.target.value;

                // Validate schedule date
                const scheduleDateError = scheduleDateValidation(date);
                if (scheduleDateError) return errorToaster(scheduleDateError);

                // Update schedule date
                await updateData({ scheduleDate: date.split("T")[0] });
            },
            async () => {
                // Set loading state
                setStates((prevState) => ({ ...prevState, uploadLoading: false }));
            }
        );
    };

    // Update data
    const updateContent = async () => {
        confirmAlert(
            "Update Schedule Content",
            "Are you sure you want to update schedule?",
            async () => {
                // Show loader
                setStates((prevState) => ({ ...prevState, uploadLoading: true }));
                // Validate details
                const detailsError = validateTextLength(details, "Details", { min: 20, max: 1000 });
                if (detailsError) return errorToaster(detailsError);

                // Make data
                const data = { details };

                // Update data
                await updateData(data);
            },
            async () => {
                // Set loading state
                setStates((prevState) => ({ ...prevState, uploadLoading: false }));
            }
        );
    };

    // Update data
    const updateData = async (data) => {
        axios
            .put(`${adminApiUrl}update/${scheduleUpdateId}`, data, {
                headers: {
                    Authorization: `Bearer ${Cookies.get("token")}`,
                },
            })
            .then((response) => {
                if (response?.data?.success) {
                    successToaster("Updated successfully");

                    // Get updated data
                    getUpdateData();
                } else {
                    errorToaster("Something went wrong");
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                errorToaster("Something went wrong");
            })
            .finally(() => {
                // Hide loader
                setStates((prevState) => ({ ...prevState, uploadLoading: false }));
            });
    };

    // Call when page load
    useEffect(() => {
        getUpdateData();
    }, []);

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="Publish Schedule" subtitle="Share schedule on website" />
            {uploadLoading && <Loader />}
            <Card variant="outlined" sx={{ my: 5 }}>
                <form noValidate autoComplete="off">
                    <CardContent>
                        <Box p={3}>
                            <AppBar position="static" style={{ height: "60px", padding: "15px" }}>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>
                                        <Input sx={{ width: "17.8vw" }} disabled value={title} />
                                        &nbsp;&nbsp;|&nbsp;&nbsp;
                                        <span>Schedule Date &nbsp;&nbsp; : &nbsp;&nbsp;</span>
                                        <Input sx={{ width: "6vw" }} type="date" className="myDate" onChange={(e) => updateScheduleDate(e)} />
                                    </div>
                                    <div>
                                        <Button onClick={updateContent} variant="contained" endIcon={<PublishIcon />}>
                                            Publish
                                        </Button>
                                    </div>
                                </Box>
                            </AppBar>
                            <Box mt={5}>
                                <JoditEditor ref={editor} value={details} tabIndex={1} onBlur={(newContent) => updateState("details", newContent)} config={config} />
                            </Box>
                        </Box>
                    </CardContent>
                </form>
            </Card>
        </Box>
    );
}
