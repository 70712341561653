// Function to validate text length within a specified range
const validateTextLength = (text, field, { min, max }) => {
    // Check if text is empty or null
    if (!text) {
        // Return error message if text is missing
        return `${field} is required.`;
    }

    // Check if text length is outside the specified range
    if (text.length < min || text.length > max) {
        // Return error message if length is invalid
        return `${field} must be between ${min} and ${max} characters.`;
    }

    // Return null if text length is valid
    return null;
};

// Function to validate email format
const validateEmail = (email) => {
    // Check if email is empty or null
    if (!email) {
        // Return error message if email is missing
        return "Email is required.";
    }

    // Check if the email format is valid using a regular expression
    if (!/\S+@\S+\.\S+/.test(email)) {
        // Return error message if email format is invalid
        return "Email is invalid.";
    }

    // Return null if email is valid
    return null;
};

// Function to validate mobile number format
const validateMobile = (mobile) => {
    // Check if mobile number is empty or null
    if (!mobile) {
        // Return error message if mobile number is missing
        return "Mobile is required.";
    }

    // Check if the mobile number format is valid using a regular expression
    if (!/^[6-9]\d{9}$/.test(mobile)) {
        // Return error message if mobile number format is invalid
        return "Mobile is invalid.";
    }

    // Return null if mobile number is valid
    return null;
};

// Function to validate file format and size
const validateFile = (file, field, { types, min, max }) => {
    // Check if file is empty or null
    if (!file) {
        // Return error message if file is missing
        return `${field} is required.`;
    }

    // Check if file type is valid using the allowed types array
    if (!types.includes(file.type.split("/")[1])) {
        // Return error message if file type is invalid
        return `${field} type must be ${types.join(", ")}.`;
    }

    // Check if file size is outside the specified range
    if (file.size < min || file.size > max) {
        // Return error message if file size is invalid
        return `${field} size must be between ${Math.round(min / 1024)} KB and ${max / (1024 * 1024)} MB.`;
    }

    // Return null if file is valid
    return null;
};

// Function to validate url format
const validateUrl = (url, field, { min, max }) => {
    // Check if url is empty or null
    if (!url) {
        // Return error message if url is missing
        return `${field} is required.`;
    }

    // Check if the url format is valid using a regular expression
    if (!/^(ftp|http|https):\/\/[^ "]+$/.test(url)) {
        // Return error message if url format is invalid
        return `${field} is invalid.`;
    }

    // Check if url length is outside the specified range
    if (url.length < min || url.length > max) {
        // Return error message if length is invalid
        return `${field} must be between ${min} and ${max} characters.`;
    }

    // Return null if url is valid
    return null;
};

// Function to validate array length within a specified range
const validateArrayLength = (array, field, { min, max }) => {
    // Check if array is empty or null
    if (!array || !array.length) {
        // Return error message if array is missing
        return `${field} is required.`;
    }

    // Check if array length is outside the specified range
    if (array.length < min || array.length > max) {
        // Return error message if length is invalid
        return `${field} must be between ${min} and ${max} items.`;
    }

    // Return null if array length is valid
    return null;
};

// Exporting the validation functions
export { validateTextLength, validateEmail, validateMobile, validateFile, validateUrl, validateArrayLength };
