// Description: This file contains the main app component
import Cookies from "js-cookie";
import { themeSettings } from "theme";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { CssBaseline, ThemeProvider } from "@mui/material";

// Import Decrypt
import { decrypt } from "shared/encryption";

// Local Imports
import AppRouter from "./AppRouter";

// Inside your App component
const App = () => {
    // Retrieve the value from cookies
    const cookieValue = Cookies.get("isAuthenticated");

    // Decrypt the value and set it to isAuthenticatedValue
    const isAuthenticatedValue = cookieValue ? decrypt(cookieValue) : false;

    // Theme Settings
    const mode = useSelector((state) => state.global.mode);
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    // Check if user is authenticated
    const isAuthenticated = useSelector((state) => state.global.isAuthenticated) || isAuthenticatedValue;

    // log isAuthenticated
    console.log("isAuthenticated: ", isAuthenticated);

    // Save the theme to local storage (used for toaster & alerts)
    localStorage.setItem("theme", mode);

    // Return the app component
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <AppRouter isAuthenticated={isAuthenticated} />
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
