//---------- import Components
import axios from "axios";
import Cookies from "js-cookie";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Box, Paper, AppBar, Toolbar, Button } from "@mui/material";

// Loader Component
import Loader from "shared/Loader";

// Toaster import
import { successToaster, errorToaster } from "shared/toaster";

// Import validation file
import { validateFile } from "shared/validation";

// Sweet alerts
import { confirmAlert } from "shared/alerts";

// Constant variables
import { constants } from "shared/constants";

// Visually hidden input
const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

// ADMIN API URL
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

const Slider = () => {
    // Variables declaration
    const [images, setImages] = React.useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(true);

    // Destructuring apiUrl from constants
    const { ALLOWED_IMAGE_TYPES, MAX_BANNER_SIZE, MIN_BANNER_SIZE } = constants;

    // Handle image change
    const handleSliderChange = (e) => {
        if (e.target.files.length > 0) {
            // Confirm alert
            confirmAlert("Create Slider", "Are you sure you want to upload this image?", () => {
                setUploadLoading(true);

                // Validate the file
                const err = validateFile(e.target.files[0], e.target.files[0].name, {
                    types: ALLOWED_IMAGE_TYPES,
                    min: MIN_BANNER_SIZE,
                    max: MAX_BANNER_SIZE,
                });
                if (err) {
                    errorToaster(err);
                    setUploadLoading(false);
                    return;
                }

                const formData = new FormData();
                formData.append("slider", e.target.files[0]);
                axios
                    .post(adminApiUrl + "slider", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: "Bearer " + Cookies.get("token"),
                        },
                    })
                    .then((response) => {
                        const result = response.data;
                        if (result.success) {
                            successToaster("Slider Uploaded Successfully");
                            // Add the new slider to the state, at top position
                            const newImages = [result.slider, ...images];
                            setImages(newImages);
                        } else {
                            errorToaster(result.message);
                        }
                    })
                    .catch((error) => {
                        console.error("Slider Upload Error:", error);
                        errorToaster("Something went wrong");
                    })
                    .finally(() => {
                        setUploadLoading(false);
                    });
            }, async () => {
                setUploadLoading(false);
            });
        }
    };

    // Fetch sliders from the API
    useEffect(() => {
        axios
            .get(adminApiUrl + "sliders", {
                headers: {
                    Authorization: "Bearer " + Cookies.get("token"),
                },
            })
            .then((response) => {
                const result = response.data;
                if (result.success) {
                    setImages(result?.sliders);
                } else {
                    if (result.message !== "ok") errorToaster(result.message);
                    console.log(result.message);
                }
                setFetchLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setFetchLoading(false);
            });
    }, []);

    const handleDelete = (index) => {
        // Confirm alert
        confirmAlert("Delete Slider", "Are you sure you want to delete this slider?", () => {
            setUploadLoading(true);
            axios
                .delete(adminApiUrl + "slider/" + images[index]._id, {
                    headers: {
                        Authorization: "Bearer " + Cookies.get("token"),
                    },
                })
                .then((response) => {
                    const result = response.data;
                    if (result.success) {
                        successToaster("Slider Deleted Successfully");
                        // Remove the slider from the state
                        const newImages = images.filter((image, i) => i !== index);
                        setImages(newImages);
                    } else {
                        errorToaster(result.message);
                    }
                })
                .catch((error) => {
                    console.error("Slider Delete Error:", error);
                    errorToaster("Something went wrong");
                })
                .finally(() => {
                    setUploadLoading(false);
                });
        }, async () => {
            setUploadLoading(false);
        });
    };

    return (
        <Box py={5}>
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
                <AppBar position="static" style={{ width: "1500px" }}>
                    <Toolbar>
                        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            Upload Image
                            <VisuallyHiddenInput type="file" accept="image/*" multiple={false} onChange={handleSliderChange} />
                        </Button>
                    </Toolbar>
                </AppBar>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Paper variant="outlined" sx={{ width: 1500, height: 700, overflowY: "scroll", mt: 2 }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            {fetchLoading ? (
                                <Loader />
                            ) : (
                                <TableBody>
                                    {images.map((image, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <img
                                                    src={`https://api.sunilmendhe.in/${image?.sliderUrl}`}
                                                    alt={image._id}
                                                    width="250"
                                                    height="170"
                                                    style={{
                                                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.8)",
                                                        borderRadius: 5,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => handleDelete(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
            {uploadLoading && <Loader />}
        </Box>
    );
};

export default Slider;
