import axios from "axios";
import io from "socket.io-client";
import Header from "components/Header";
import Cookies from "js-cookie";
import StatBox from "components/StatBox";
import FlexBetween from "components/FlexBetween";
import React, { useState, useEffect } from "react";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import { Box, Button, Typography, useTheme, useMediaQuery } from "@mui/material";
import { DownloadOutlined, Email, PointOfSale, PersonAdd, Traffic } from "@mui/icons-material";

// Toaster import
import { errorToaster } from "shared/toaster";

// Loader Component
import Loader from "shared/Loader";

// Socket URL
const socketUrl = process.env.REACT_APP_SOCKET_URL;

// Create socket connection
const socket = io(socketUrl);

// API URL
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

const Dashboard = () => {
    // Initialize state using useState hook
    const [loading, setLoading] = useState(true);

    // Initialize state using useState hook
    const [users, setUsers] = useState(0);
    const [members, setMembers] = useState(0);
    const [visitors, setVisitors] = useState(0);
    const [liveUsers, setLiveUsers] = useState(0);
    const [subscribers, setSubscribers] = useState(0);
    const [increaseUsers, setIncreaseUsers] = useState(0);
    const [increaseMembers, setIncreaseMembers] = useState(0);
    const [increaseVisitors, setIncreaseVisitors] = useState(0);
    const [increaseSubscribers, setIncreaseSubscribers] = useState(0);

    // Convert first letter to uppercase
    const toUpp = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    // Function to fetch about data
    const fetchData = async (url, stateSetter, increaseSetter) => {
        try {
            const response = await axios.get(adminApiUrl + url + "/count", {
                headers: {
                    Authorization: "Bearer " + Cookies.get("token"),
                },
            });
            const result = response.data;

            if (result.success) {
                const active = result[`active${toUpp(url)}`];
                const inactive = result[`inactive${toUpp(url)}`];
                const total = active + inactive;
                const lastMonth = result[`lastMonth${toUpp(url)}`];

                // Set state
                stateSetter(total);

                let increase = Math.round((lastMonth / total) * 100);
                if (isNaN(increase)) increase = 0;

                increaseSetter(increase);
            } else {
                errorToaster(result.message);
            }
        } catch (error) {
            errorToaster(error.message);
        }
    };

    // Function to fetch data and set loading to false
    const fetchDataAndSetLoading = async () => {
        await fetchData("subscribers", setSubscribers, setIncreaseSubscribers);
        await fetchData("members", setMembers, setIncreaseMembers);
        await fetchData("users", setUsers, setIncreaseUsers);
        await fetchData("visitors", setVisitors, setIncreaseVisitors);

        console.log(subscribers);
        setLoading(false);
    };

    // Fetch initial data when component mounts
    useEffect(() => {
        fetchDataAndSetLoading();

        socket.on("clientCount", (data) => {
            setLiveUsers(data);
        });

        console.log(`Member count: ${members} | Last month: ${increaseMembers}%`);

        return () => {
            socket.disconnect();
        };
    }, []);

    // Theme and media query
    const theme = useTheme();
    const isNonMediumScreens = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
                {loading && <Loader />}
                <Box>
                    <Button
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <DownloadOutlined sx={{ mr: "10px" }} />
                        Download Reports
                    </Button>
                </Box>
            </FlexBetween>

            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
                }}
            >
                {/* ROW 1 */}
                <StatBox title="Total Subscribers" value={subscribers} increase={`+${increaseSubscribers}%`} description="Since last month" icon={<Email sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />} />
                <StatBox title="Issued letters" value="6200" increase="+21%" description="Since last month" icon={<PointOfSale sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />} />
                <Box gridColumn="span 8" gridRow="span 2" backgroundColor={theme.palette.background.alt} p="1rem" borderRadius="0.55rem">
                    {/* Something can be added later */}
                    <Typography p="0 0.6rem" fontSize="0.8rem" sx={{ color: theme.palette.secondary[200] }}>
                        Space left intentionally for something to be added here later on
                    </Typography>
                </Box>
                <StatBox title="Total Members" value={users} increase={`+${increaseUsers}%`} description="Since last month" icon={<PersonAdd sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />} />
                <StatBox title="Total Visitors" value={visitors} increase={`+${increaseVisitors}%`} description="Since last month" icon={<Traffic sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />} />
                <StatBox title="Live Users" value={liveUsers} description="are currently live" icon={<OnlinePredictionIcon sx={{ color: theme.palette.secondary[300], fontSize: "26px" }} />} />

                {/* ROW 2 */}
                <Box
                    gridColumn="span 8"
                    gridRow="span 3"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            borderRadius: "5rem",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: theme.palette.background.alt,
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: theme.palette.background.alt,
                            color: theme.palette.secondary[100],
                            borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.secondary[200]} !important`,
                        },
                    }}
                >
                    <Typography p="0 0.6rem" fontSize="0.8rem" sx={{ color: theme.palette.secondary[200] }}>
                        Here any data table can be added later on
                    </Typography>
                </Box>
                <Box gridColumn="span 4" gridRow="span 3" backgroundColor={theme.palette.background.alt} p="1.5rem" borderRadius="0.55rem">
                    <Typography variant="h6" sx={{ color: theme.palette.secondary[100] }}></Typography>
                    {/* Any Chart can be added later on */}
                    <Typography p="0 0.6rem" fontSize="0.8rem" sx={{ color: theme.palette.secondary[200] }}>
                        Here any Chart can be added later on
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
