import axios from "axios";
import Cookies from "js-cookie";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Header from "components/Header";
import Dialog from "@mui/material/Dialog";
import { CameraAlt } from "@mui/icons-material";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import CardContent from "@mui/material/CardContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

// Loader
import Loader from "shared/Loader";

// Import validation file
import { validateTextLength, validateFile } from "shared/validation";

// Toaster import
import { successToaster, errorToaster } from "shared/toaster";

// Constant variables
import { constants } from "shared/constants";

// Sweet alerts
import { confirmAlert } from "shared/alerts";

// API URL
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

export default function About() {
    // Initialize state using useState hook
    const [state, setState] = useState({
        imgUrl: "",
        aboutId: "",
        open: false,
        textData: "",
        uploadLoading: false,
        showUploadIcon: false,
    });

    // Destructure state values for easier access
    const { open, imgUrl, textData, showUploadIcon, uploadLoading, aboutId } = state;

    // Desctructure contant values for easier access
    const { MAX_PROFILE_PICTURE_SIZE, MIN_PROFILE_PICTURE_SIZE, ALLOWED_IMAGE_TYPES } = constants;

    // Function to handle mouse enter event
    const handleMouseEnter = () => setState({ ...state, showUploadIcon: true });

    // Function to handle mouse leave event
    const handleMouseLeave = () => setState({ ...state, showUploadIcon: false });

    // Function to handle text input change event
    const handleTextChange = ({ target }) => setState({ ...state, textData: target.value });

    // Function to open modal
    const handleClickOpen = () => setState({ ...state, open: true });

    // Function to close modal
    const handleClose = () => setState({ ...state, open: false });

    // Function to handle icon click event
    const handleIconClick = () => document.getElementById("hiddenFileInput").click();

    // Async function to fetch about data from the server
    const getAboutData = async () => {
        try {
            const response = await axios.get(adminApiUrl + "about", {
                headers: {
                    Authorization: "Bearer " + Cookies.get("token"),
                },
            });

            // Destructure data from response
            const result = response.data;

            if (result.success) {
                setState({
                    ...state,
                    aboutId: result?.about?._id,
                    textData: result?.about?.introduction,
                    imgUrl: result?.about?.profilePicUrl,
                });
            } else {
                if (result.message !== "ok") errorToaster(result?.message);
                console.log(result.message);
            }
        } catch (error) {
            console.error("About Data Error:", error);
            errorToaster("Something went wrong");
        }
    };

    // Async function to upload text data to the server
    const uploadText = async () => {
        // Show confirmation alert
        confirmAlert(
            "Update Introduction",
            "Are you sure you want to update?",
            async () => {
                try {
                    // Show loader
                    setState((prevState) => ({ ...prevState, uploadLoading: true }));

                    // Validate text data
                    const isErr = validateTextLength(textData, "About Text", { min: 50, max: 10000 });
                    if (isErr) return errorToaster(isErr);

                    // Upload text data
                    const response = await axios.put(
                        adminApiUrl + "about/" + aboutId,
                        { introduction: textData },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + Cookies.get("token"),
                            },
                        }
                    );

                    // Destructure data from response
                    const result = response.data;

                    if (result.success) {
                        successToaster("Text Uploaded Successfully");
                        setState({ ...state, textData: result?.about?.introduction, open: false });
                    } else {
                        if (result.message !== "ok") errorToaster(result?.message);
                        console.log(result.message);
                    }
                } catch (error) {
                    console.error("About Text Upload Error:", error);
                    errorToaster("Something went wrong");
                } finally {
                    // Hide loader
                    setState((prevState) => ({ ...prevState, uploadLoading: false }));
                }
            },
            async () => {
                await getAboutData();
                handleClose();
            }
        );
    };

    // Function to handle image change event
    const handleImageChange = async (event) => {
        // Show confirmation alert
        confirmAlert(
            "Update Profile Picture",
            "Are you sure you want to update?",
            async () => {
                // Show loader
                setState((prevState) => ({ ...prevState, uploadLoading: true }));

                if (event.target.files.length > 0) {
                    // Validate image size
                    const isErr = validateFile(event.target.files[0], "Profile Picture", {
                        types: ALLOWED_IMAGE_TYPES,
                        min: MIN_PROFILE_PICTURE_SIZE,
                        max: MAX_PROFILE_PICTURE_SIZE,
                    });

                    // Return if error
                    if (isErr) return errorToaster(isErr);

                    // Set file to upload
                    const formData = new FormData();
                    formData.append("profilePic", event.target.files[0]);
                    try {
                        const response = await axios.put(adminApiUrl + "about/" + aboutId, formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: "Bearer " + Cookies.get("token"),
                            },
                        });

                        // Destructure data from response
                        const result = response.data;

                        if (result.success) {
                            successToaster("Image Uploaded Successfully");
                            getAboutData();
                        } else {
                            if (result.message !== "ok") errorToaster(result.message);
                            console.log(result.message);
                        }
                    } catch (error) {
                        console.error("Image Upload Error:", error);
                        errorToaster("Something went wrong");
                    } finally {
                        // Hide loader
                        setState((prevState) => ({ ...prevState, uploadLoading: false }));
                    }
                }
            },
            async () => {
                await getAboutData();
                handleClose();
            }
        );
    };

    // Fetch initial data when component mounts
    useEffect(() => {
        getAboutData();
    }, []);

    return (
        <Box m="1.5rem 2.5rem">
            <Header title="About Section" subtitle="Edit / Update the About section's content" />
            {uploadLoading && <Loader />}
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box pt={10}>
                    <Card
                        variant="outlined"
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "1400px",
                        }}
                    >
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <CardContent sx={{ flex: "1 0 auto" }}>
                                <Typography variant="h5" color="text.secondary" component="div" sx={{ p: 3, textAlign: "justify" }}>
                                    {textData}
                                </Typography>
                            </CardContent>
                        </Box>
                        <Box
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            sx={{
                                position: "relative",
                                backgroundColor: showUploadIcon ? "rgba(0, 0, 0, 0.6)" : "transparent",
                                transition: "background-color 0.2s ease-in-out",
                                zIndex: 500,
                            }}
                        >
                            <CardMedia
                                component="img"
                                sx={{
                                    width: 300,
                                    height: 300,
                                    p: 3,
                                    borderRadius: 50,
                                    transform: "translate(0, +38%)",
                                }}
                                image={`https://api.sunilmendhe.in/${imgUrl}`}
                                alt="Live from space album cover"
                            />
                            <IconButton
                                aria-label="Upload Image"
                                onClick={handleIconClick}
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    display: showUploadIcon ? "block" : "none",
                                    color: "black",
                                }}
                            >
                                <CameraAlt fontSize="large" />
                                <input id="hiddenFileInput" type="file" accept="image/*" style={{ display: "none" }} multiple={false} onChange={handleImageChange} />
                            </IconButton>
                        </Box>
                    </Card>
                    <div>
                        <Button variant="contained" onClick={handleClickOpen} sx={{ mt: 1 }}>
                            Edit Introduction
                        </Button>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            sx={{
                                "& .MuiDialog-paper": {
                                    width: "100%",
                                    minWidth: "60vw",
                                    minHeight: "60vh",
                                },
                            }}
                        >
                            <DialogTitle>Introduction Editor</DialogTitle>
                            <DialogContent>
                                <TextField autoFocus margin="dense" id="name" label="Enter your content here" type="text" fullWidth multiline rows={30} value={textData} onChange={handleTextChange} />
                            </DialogContent>

                            <DialogActions>
                                <Button variant="contained" onClick={handleClose}>
                                    Cancel
                                </Button>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        uploadText();
                                        handleClose();
                                    }}
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Box>
            </Box>
        </Box>
    );
}
