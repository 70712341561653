// sweetAlert.js
import Swal from "sweetalert2";

// Alert Function
export const confirmAlert = async (title, text, confirmCallback, cancelCallback = null, confirmButtonText = "Okay", cancelButtonText = "Cancel") => {
    // Get the theme
    const theme = localStorage.getItem("theme") || "dark";

    // Set the background and color
    const background = theme === "dark" ? "#1e1e1e" : "#fff";
    const color = theme === "dark" ? "#fff" : "#000";

    // Show the alert
    Swal.fire({
        title,
        text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        background: background,
        color: color,
    }).then((result) => {
        if (result.value) {
            if (confirmCallback) {
                confirmCallback();
            }
        } else {
            Swal.fire({
                title: "Cancelled",
                text: "The operation has been cancelled.",
                background: background,
                color: color,
            });

            if (cancelCallback) {
                cancelCallback();
            }
        }
    });
};
