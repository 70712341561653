// Desc: Get user data from cookies
import Cookies from "js-cookie";

// Import Decrypt
import { decrypt } from "shared/encryption";

export const getUserData = () => {
    // Get user data from cookies
    const userData = Cookies.get("userData");

    // Initialize variables for destructured data
    let role, userName, email, profilePic;

    // Check if userData exists and is a valid JSON string
    if (userData) {
        try {
            // Decode user data
            const decodedUserData = decrypt(userData);

            // Destructure user data
            ({ role, userName, email, profilePic } = decodedUserData);
        } catch (error) {
            console.error("Error decoding or parsing user data:", error);
        }
    }

    // Return all values
    return { role, userName, email, profilePic };
};
