import axios from "axios";
import Cookies from "js-cookie";
import JoditEditor from "jodit-react";
import React, { useState, useRef } from "react";
import PublishIcon from "@mui/icons-material/Publish";
import { Box, Paper, AppBar, Toolbar, Button, Typography, TextField } from "@mui/material";

// Loader
import Loader from "shared/Loader";

// Header component
import Header from "components/Header";

// Import validation file
import { validateTextLength, validateFile } from "shared/validation";

// Import toaster
import { successToaster, errorToaster } from "shared/toaster";

// Sweet alerts
import { confirmAlert } from "shared/alerts";

// Constant variables
import { constants } from "shared/constants";

// ADMIN API URL
const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;

export default function PostNewsletter() {
    // Jodit editor configuration
    const editor = useRef(null);
    const fileInputRef = useRef(null);

    // Editor configuration
    const config = {
        height: 500,
        theme: "dark",
    };

    // Consolidated state into a single object
    const [formData, setFormData] = useState({
        content: "",
        subject: "",
        selectedFile: null,
        uploadLoading: false,
    });

    // Destructure formData state for ease of use
    const { content, subject, selectedFile, uploadLoading } = formData;

    // Desctructure contant values for easier access
    const { MAX_ATTACHMENT_SIZE, MIN_ATTACHMENT_SIZE, ALLOWED_DOCUMENT_TYPES } = constants;

    // Handle file change event
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        // Validate file
        const fileError = validateFile(selectedFile, "Attachment file", {
            types: ALLOWED_DOCUMENT_TYPES,
            min: MIN_ATTACHMENT_SIZE,
            max: MAX_ATTACHMENT_SIZE,
        });
        if (fileError) {
            setFormData({
                ...formData,
                selectedFile: null,
            });
            fileInputRef.current.value = "";
            return errorToaster(fileError);
        }

        // Update selectedFile in formData state
        setFormData({
            ...formData,
            selectedFile,
        });
    };

    // Handle content change event
    const setContent = (newContent) => {
        setFormData({
            ...formData,
            content: newContent,
        });
    };

    // Handle subject change event
    const setSubject = (newSubject) => {
        setFormData({
            ...formData,
            subject: newSubject,
        });
    };

    // Handle removing selected file
    const handleRemoveFile = () => {
        fileInputRef.current.value = "";
        // Update selectedFile to null in formData state
        setFormData({
            ...formData,
            selectedFile: null,
        });
    };

    // Handle form submission
    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Show confirmation alert
        confirmAlert(
            "Post Newsletter",
            "Are you sure you want to post this newsletter?",
            async () => {
                // Set error array
                const isAnyErr = [];

                // Validate subject
                const subjectError = validateTextLength(subject, "Subject", {
                    min: 10,
                    max: 100,
                });
                if (subjectError) isAnyErr.push(subjectError);

                // Validate content
                const contentError = validateTextLength(content, "Newsletter content", {
                    min: 20,
                    max: 100000,
                });
                if (contentError) isAnyErr.push(contentError);

                // Validate selectedFile
                const fileError = validateFile(selectedFile, "Attachment file", {
                    types: ALLOWED_DOCUMENT_TYPES,
                    min: MIN_ATTACHMENT_SIZE,
                    max: MAX_ATTACHMENT_SIZE,
                });
                if (fileError) isAnyErr.push(fileError);

                // Check if any error
                if (isAnyErr.length > 0) {
                  isAnyErr.forEach((err) => errorToaster(err));
                  setFormData((prevStates) => ({
                      ...prevStates,
                      uploadLoading: false,
                  }));
                  return;
                }

                // Creating FormData
                const formToUpload = new FormData();
                formToUpload.append("content", content);
                formToUpload.append("subject", subject);
                formToUpload.append("attachment", selectedFile);

                // API call
                axios
                    .post(adminApiUrl + "newsletter", formToUpload, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: "Bearer " + Cookies.get("token"),
                        },
                    })
                    .then((res) => {
                        successToaster(res.data.message);
                        // Reset formData after successful submission
                        setFormData({
                            content: "",
                            subject: "",
                            selectedFile: null,
                            uploadLoading: false,
                        });
                    })
                    .catch((err) => {
                        setFormData((prevData) => ({
                            ...prevData,
                            uploadLoading: false,
                        }));
                        errorToaster(err.response.data.message);
                    });
            },
            async () => {
                setFormData((prevData) => ({
                    ...prevData,
                    uploadLoading: false,
                }));
            }
        );
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" m="1.5rem 2.5rem">
            <Header title="Publish Newsletter" subtitle="Send Newsletter to Subscribers" />
            {uploadLoading && <Loader />}
            <form autoComplete="off" encType="multipart/form-data">
                <AppBar position="static" style={{ margin: "36px 0" }}>
                    <Toolbar>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <Button variant="contained" endIcon={<PublishIcon />} onClick={handleFormSubmit}>
                                    Publish
                                </Button>
                            </div>
                            <div>
                                <Typography>Selected file: {selectedFile ? selectedFile.name : "No file selected"}</Typography>
                            </div>
                            <div>
                                <Button variant="contained" color="primary" onClick={() => fileInputRef.current.click()}>
                                    Select PDF File
                                </Button>
                                {selectedFile && (
                                    <Button variant="outlined" color="secondary" onClick={handleRemoveFile} sx={{ ml: 2 }}>
                                        Remove Selected PDF
                                    </Button>
                                )}
                                <input type="file" accept="application/pdf" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
                            </div>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Paper variant="outlined">
                    <Box px={7} py={5}>
                        <TextField fullWidth label="Write Subject here" variant="standard" sx={{ mb: 3 }} value={subject} onChange={(e) => setSubject(e.target.value)} />
                        <JoditEditor
                            ref={editor}
                            value={content}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            onChange={(newContent) => {}}
                            config={config}
                        />
                    </Box>
                </Paper>
            </form>
        </Box>
    );
}
