import Cookies from "js-cookie";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_ADMIN_API_URL }),
    reducerPath: "adminApi",
    tagTypes: ["Subscribers"],
    endpoints: (build) => ({
        getSubscribers: build.query({
            query: ({ page, limit, sort, search }) => ({
                url: "/subscribers",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Cookies.get("token")}`,
                },
                method: "GET",
                params: { page, limit, sort, search },
            }),
            providesTags: ["Subscribers"],
        }),
    }),
});

export const { useGetSubscribersQuery } = api;
