import "./index.css";
import App from "./App";
import React from "react";
import globalReducer from "state";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { configureStore } from "@reduxjs/toolkit";

// Get API
import { api } from "./state/api";

// Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Create Redux Store
const store = configureStore({
    reducer: {
        global: globalReducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

const rootElement = document.getElementById("root");
createRoot(rootElement).render(
    <Provider store={store}>
        <App />
        <ToastContainer />
    </Provider>
);
