import Cookies from "js-cookie";
import React, { useEffect } from "react";
import Subscribers from "scenes/subscribers";
import { useNavigate } from "react-router-dom";
import { Box, AppBar, Toolbar, Button, Typography } from "@mui/material";

export default function Newsletter() {
    const navigate = useNavigate();

    // Initialize state using useState hook
    const [totalSubscribers, setTotalSubscribers] = React.useState(0);

    // Get total subscribers
    const getTotalSubscribers = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_ADMIN_API_URL}subscribers/count`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Cookies.get("token")}`,
                },
            });
            const data = await response.json();
            if (data.success) {
                setTotalSubscribers(data.activeSubscribers + data.inactiveSubscribers);
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Get total subscribers
    useEffect(() => {
        getTotalSubscribers();
    }, []);

    return (
        <div>
            <Box display="flex" justifyContent="center">
                <AppBar position="static" style={{ width: "1535px", margin: "36px 0 20px 0" }}>
                    <Toolbar>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>
                                <Typography variant="h5">Total Subscribers: {totalSubscribers}</Typography>
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        navigate(`/newsletter/post`);
                                    }}
                                >
                                    Post Newsletter
                                </Button>
                            </div>
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>
            <Subscribers />
        </div>
    );
}
